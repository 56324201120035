<template>
  <div 
    style="width:100%;height:100%;background-color:transparent;">
    <setting-layout v-if="show" :modal="modal" :customStyle="customStyle"/>
  </div>
</template>

<script>

export default {
  props: ['modal', 'customStyle'],
  name: 'Home',
  watch: {
    '$route.fullPath': {
      handler: function() {
          // this.$forceUpdate()
          let self = this
          self.show = false
          setTimeout(function(){
            self.show = true
          }, 200)
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      show: false,
    }
  }
}
</script>
